import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import React from 'react';

export const FeedbackRadioButton = ({
  content,
  name,
  handleChange,
  feedback,
}) => {
  return (
    <div>
      <span className="text-danger">
        {content} *
      </span>
      <div>

        <FormControl required>
          <RadioGroup name={name} onChange={handleChange} value={feedback}>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};
